import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import shareimg from '../assets/images/share.svg';
import * as APIService from "../api/APIService.jsx";

export default function DetailedGroup(props) {

  const collegeIdData = useSelector(state => state).collegeId;
  const [data, setData] = useState();

  useEffect(() => {
    getGroupDetailData();
  }, [])

  const getGroupDetailData = async () => {
    try {
      if (collegeIdData?.college_id?.id) {
        const response = await APIService.getGroupDetailData(collegeIdData?.college_id?.id);
        console.log("res", response?.detailData)
        // setDetailData(response?.detailData);
        setData(response?.detailData);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const postMessageData = (count, guid, role) => {
    console.log("postdata message", count)
    window.parent.postMessage({
      action: "message",
      groupid: guid,
      role: role,
      count: count,
    }, "*");
  }

  const postParticipantData = (count, guid, role) => {
    console.log("postdata message", count)
    window.parent.postMessage({
      action: "participants",
      groupid: guid,
      role: role,
      count: count,
    }, "*");
  }

  return (
    <div className='detailed-group-main'>

      {
        data?.groupdetail.map((e, i) => {
          return (
            <div className="detailed-group-main-inner" key={i}>

              <div style={{ width: '30%', display: 'flex', flexDirection: 'column', borderRight: '1px solid #CBD6E2' }}>
                <div style={{ height: '46px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  <div className='big-circle div-center'>
                    {
                      e.group_avatar ?
                        <img src={e.group_avatar} alt="message image" style={{ width: '45px', height: '45px', borderRadius: '28px' }} />
                        :
                        <span className='capital-letter'>{e.group_name.split("")[0]}</span>
                    }
                  </div>
                  <div className='big-text' style={{ marginLeft: '10px' }}>{e.group_name}</div>
                </div>
                {
                  e.sentiment ?
                    <div className='sentiment-text' style={{ marginLeft: '56px' }}>
                      <span>Sentiment:</span>
                      <span className='sentiment-color-p' style={{ marginLeft: '10px' }}>{e.sentiment}</span>
                      {/* <span className='sentiment-color-n'>Neutral</span>
                      <span className='sentiment-color-e'>Negative</span> */}
                    </div>
                    : null
                }
              </div>

              <div style={{ width: '45%', borderRight: '1px solid #CBD6E2', padding: '0px 20px' }}>
                <div className='table-header-text'>Total messages: {e.total_conversations}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className='tm-chips'>
                    <span>
                      Admins: {e.grp_conv_by_role.admin}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postMessageData(e.grp_conv_by_role.admin, e.guid, 3) }} />
                  </div>
                  <div className='tm-chips' style={{ margin: '5px 10px' }}>
                    <span>
                      Ambassadors: {e.grp_conv_by_role.ambassador}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postMessageData(e.grp_conv_by_role.ambassador, e.guid, 4) }} />
                  </div>
                  <div className='tm-chips'>
                    <span>
                      Prospects: {e.grp_conv_by_role.prospect}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postMessageData(e.grp_conv_by_role.prospect, e.guid, 5) }} />
                  </div>
                </div>
                {/* comment for future use */}
                {/* <div className='table-inner'>
                  <div className='table-date-text div-center'>
                    26 Oct 24
                  </div>
                  <div className='table-inner-text'>
                    Last message by Vikram
                  </div>
                </div>
                <div className='table-inner-desc'>I will be graduating in December 2024. Outside of the classroom I do a lot of hiking, swimming, and playing with my dog.</div> */}
              </div>

              <div style={{ width: '45%', paddingLeft: '20px' }}>
                <div className='table-header-text'>Total participants: {e.total_participants}</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className='tp-chips'>
                    <span>
                      Admins: {e.role_counts.admin}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postParticipantData(e.role_counts.admin, e.guid, 3) }} />
                  </div>
                  <div className='tp-chips' style={{ margin: '5px 10px' }}>
                    <span>
                      Ambassadors: {e.role_counts.ambassador}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postParticipantData(e.role_counts.ambassador, e.guid, 4) }} />
                  </div>
                  <div className='tp-chips'>
                    <span>
                      Prospects: {e.role_counts.prospect}
                    </span>
                    <img src={shareimg} className='shareimg' alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postParticipantData(e.role_counts.prospect, e.guid, 5) }} />
                  </div>
                </div>
              </div>

              {/* <div style={{ width: '22%' }}>
                <div className='table-header-text'>Topics discussed: 12</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div className='td-chips'>
                    Admissions
                  </div>
                  <div className='td-chips'>
                    Admissions
                  </div>
                  <div className='td-chips'>
                    Admissions
                  </div>
                  <div className='td-chips'>
                    Admissions
                  </div>
                </div>
              </div> */}

            </div>
          );
        })
      }


    </div>
  )
}
