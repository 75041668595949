import React, { useEffect, useState } from "react";
import messageimg from '../assets/images/message.svg';
import shareimg from '../assets/images/share.svg';
import { useSelector } from "react-redux";
import * as APIService from "../api/APIService.jsx";

export default function TotalMessage(props) {

  const collegeIdData = useSelector(state => state).collegeId;
  const [data, setData] = useState();

  useEffect(() => {
    getGroupOverviewData();
  }, [])

  const getGroupOverviewData = async () => {
    try {
      if (collegeIdData?.college_id?.id) {
        const response = await APIService.getGroupOverviewData(collegeIdData?.college_id?.id);
        setData(response?.result);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const postData = (count) => {
    console.log("postdata message",count)
    window.parent.postMessage({
      action: "total_message",
      count: count,
    }, "*");
  }

  return (
    <div className="group-main">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="total">
          <img src={messageimg} alt="message image" />
          <span className="total-text">Total messages</span>
          <img src={shareimg} alt="share image" style={{ cursor: 'pointer' }} onClick={() => { postData(data?.total_message_count) }} />
        </div>
        <div className="total-number">{data?.total_message_count}</div>
      </div>
      <div>
        <div className="top">
          Messages by role
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div item className="small-chips-m m-color" >
              <div className="message-text">Admins: {data?.msg_users_by_role.admin}</div>
            </div>
            <div item className="small-chips-m m-color" style={{ margin: '0px 5px' }}>
              <div className="message-text-am">Ambassadors: {data?.msg_users_by_role.ambassador}</div>
            </div>
            <div item className="small-chips-m m-color" >
              <div className="message-text">Prospects: {data?.msg_users_by_role.prospect}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
