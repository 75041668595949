const initialState = {
    collegeId: null,
};

const collegeIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COLLEGE_ID':
            return { ...state, collegeId: action.payload };
        default:
            return state;
    }
};

export default collegeIdReducer

