import "./App.css";
import "./assets/css/global.css";
import * as APIService from "./api/APIService.jsx";
import Dashboard from "./pages/Dashboard";
import ClarityAndGoogleAnalytics from './components/ClarityAndGoogleAnalytics';
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, Provider } from 'react-redux';
import { setCollegeId } from "./Redux/action/action.js";

function App() {
  const dispatch = useDispatch();
  const [clarityId, setClarityId] = useState();
  const [googleAnalyticsId, setgoogleAnalyticsId] = useState();

  useEffect(() => {
    fetchCollegeData()
    const handleResize = () => {
      window.location.reload();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("resize", handleResize);
    };
  }, []);

  window.addEventListener('click', function () {
    window.parent.postMessage('iframeClick', '*');
  });
  const fetchCollegeData = async () => {
    try {

       const collegeUrl = window.location.hostname.split(".")[0];
      // const collegeUrl = "iit";
      const collegeIdResponse = await APIService.getCollegeId(collegeUrl);
      const collegeId = collegeIdResponse?.result;
      if (collegeId) {
        dispatch(setCollegeId(collegeId));
      }
      setgoogleAnalyticsId(collegeIdResponse.result.college_id.dashboard_google_analytics)
      setClarityId(collegeIdResponse.result.college_id.dashboard_microsoft_clarity)
    }
    catch (error) {
      console.error("Error while fetching data:", error);
    }
  }

  return (    
    <div
      style={{
        // width: "100vw",
        minHeight: "80vh",
        height: "83vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F6F7FB",
      }}
    >
      <Dashboard />
      {/* <Header /> */}
      <ClarityAndGoogleAnalytics clarityId={clarityId} googleAnalyticsId={googleAnalyticsId} />
      {/* <Footer /> */}
    </div>
  );
}

export default App;


