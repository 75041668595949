import React, { useEffect, useState } from "react";
import participantimg from '../assets/images/participant.svg';
import shareimg from '../assets/images/share.svg';
import { useSelector } from "react-redux";
import * as APIService from "../api/APIService.jsx";

export default function TotalParticipant(props) {

  const collegeIdData = useSelector(state => state).collegeId;
  const [data, setData] = useState();

  useEffect(() => {
    getGroupOverviewData();
  }, [])

  const getGroupOverviewData = async () => {
    try {
      if (collegeIdData?.college_id?.id) {
        const response = await APIService.getGroupOverviewData(collegeIdData?.college_id?.id);
        setData(response?.result);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const postData = (count) => {
    console.log("postdata message",count)
    window.parent.postMessage({
      action: "total_participant",
      count: count,
    }, "*");
  }

  return (
    <div className="group-main">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className="total">
          <img src={participantimg} alt="message image" />
          <span className="total-text">Total participants</span>
          <img src={shareimg} alt="share image" style={{cursor:'pointer'}} onClick={() => { postData(data?.total_participents) }} />
        </div>
        <div className="total-number">{data?.total_participents}</div>
      </div>
      {/* <div>
        <div className="top">
          <img src={greenimg} alt="message image" />
          <span style={{ marginLeft: '5px' }}>Top 3</span>
        </div>
        <div>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
            {
              data?.top_participents.map((e, i) => {
                return (
                  <Grid item key={i} xs={4} sm={4} md={3.9} lg={3.9} xl={3.9} className="small-chips p-color" >
                    <div className="small-circle-p div-center">
                      <div className="small-inner-circle div-center">
                        {
                          e.profile_image ?
                            <img src={e.profile_image} alt="message image" style={{ width: '26px', height: '26px', borderRadius: '20px' }} />
                            :
                            <span className="capital-letter">{e.first_name.split("")[0]}</span>
                        }
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="small-text-message text-overflow">{e.first_name}</div>
                      <div className="small-text-role">{e.role_id === 5 ? 'Prospect' : e.role_id === 4 ? 'Ambassador' : 'Staff'}</div>
                    </div>
                  </Grid>
                )
              })
            }
          </Grid>
        </div>
      </div> */}
      <div>
        <div className="top">
          Participants by role
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div item className="small-chips-m p-color" >
              <div className="message-text">Admins: {data?.participants_by_role.admin}</div>
            </div>
            <div item className="small-chips-m p-color" style={{ margin: '0px 5px' }}>
              <div className="message-text-am">Ambassadors: {data?.participants_by_role.ambassador}</div>
            </div>
            <div item className="small-chips-m p-color" >
              <div className="message-text">Prospects: {data?.participants_by_role.prospect}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
