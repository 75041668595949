import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

const ClarityAndGoogleAnalytics = ({ clarityId, googleAnalyticsId }) => {

    useEffect(() => {

        if (!clarityId) return;

        // Load Clarity
        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
            t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
            y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", clarityId);


    }, [clarityId]);


    useEffect(() => {

        if (!googleAnalyticsId) return;
        // Load Google Analytics
        if (googleAnalyticsId) {
            ReactGA.initialize(googleAnalyticsId);
        }
    }, [googleAnalyticsId]);

    return null;
};

export default ClarityAndGoogleAnalytics;
