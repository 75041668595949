import axios from "axios";
const endpoint = "/v1/";
// const endpoint = "https://iit.community-dashboard.staging.truleague.com/v1/";
const apiKey = 'iEJ+OMVQHWhRnjCNJMvlqmxz4/H4YBLWHZ+E4Lf9JZINtrLOvYIQxWcnbbHI0A3E';

export const getGroupOverviewData = async (collegeId) => {
  const currentDate = new Date();

  const timezoneOffsetMinutes = currentDate.getTimezoneOffset();

  const sign = timezoneOffsetMinutes > 0 ? "-" : "+";
  const hours = Math.floor(Math.abs(timezoneOffsetMinutes / 60));
  const minutes = Math.abs(timezoneOffsetMinutes % 60);
  const mysqlTimezoneOffset = `${sign}${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  const url = `${endpoint}college-admin/get-group-overview/${collegeId}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'x-api-key': apiKey
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error------->", error);
    throw error;
  }
};

export const getGroupDetailData = async (collegeId) => {
  const currentDate = new Date();

  const timezoneOffsetMinutes = currentDate.getTimezoneOffset();

  const sign = timezoneOffsetMinutes > 0 ? "-" : "+";
  const hours = Math.floor(Math.abs(timezoneOffsetMinutes / 60));
  const minutes = Math.abs(timezoneOffsetMinutes % 60);
  const mysqlTimezoneOffset = `${sign}${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  const url = `${endpoint}college-admin/get-group-details/${collegeId}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'x-api-key': apiKey
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error------->", error);
    throw error;
  }
};

export const getCollegeId = async (hostUrl) => {
  const url = `${endpoint}college-admin/college_id/${hostUrl}`;
  try {
    const response = await axios.get(url, {
      headers: {
        'x-api-key': apiKey
      }
    });

    return response?.data;
  } catch (error) {
    console.error("Error------->", error);
    throw error;
  }
};

