import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import TotalGroup from "../components/TotalGroup";
import TotalParticipant from "../components/TotalParticipant";
import TotalMessage from "../components/TotalMessage";
import DetailedGroup from "../components/DetailedGroup";
import * as APIService from "../api/APIService.jsx";
import CommonDialog from "../common/CommonDialog.js";

export default function Dashboard() {

  const collegeIdData = useSelector(state => state).collegeId;

  return (
    <>
      {collegeIdData?.college_id?.id ?
        (
          <div style={{ width: '100%', background: '#F5F8FA' }}>
            <div style={{ width: '100%' }}>
              <Grid className="heading">Overview</Grid>
              <Grid container className="div-center" style={{ padding: '0px 20px' }}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <TotalGroup />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <TotalParticipant />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                  <TotalMessage />
                </Grid>
              </Grid>
            </div>
            <div style={{ width: '100%' }}>
              <Grid className="heading">Detailed group insight</Grid>
              <Grid container className="div-center" style={{ padding: '0px 30px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <DetailedGroup />
                </Grid>
              </Grid>
            </div>
          </div>
        )
        : null}
    </>
  );
}