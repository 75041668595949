import React, { useEffect, useState } from "react";
import groupimg from '../assets/images/group.svg';
import orangeimg from '../assets/images/orange.svg';
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import * as APIService from "../api/APIService.jsx";

export default function TotalGroup(props) {
    const collegeIdData = useSelector(state => state).collegeId;
    const [data, setData] = useState();

    useEffect(() => {
        getGroupOverviewData();
    }, [])

    const getGroupOverviewData = async () => {
        try {
            if (collegeIdData?.college_id?.id) {
                const response = await APIService.getGroupOverviewData(collegeIdData?.college_id?.id);
                setData(response?.result);
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        }
    };

    return (
        <div className="group-main">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="total">
                    <img src={groupimg} alt="message image" />
                    <span className="total-text">Total groups</span>
                </div>
                <div className="total-number">{data?.total_groupcount}</div>
            </div>
            <div>
                <div className="top">
                    <img src={orangeimg} alt="message image" />
                    <span style={{ marginLeft: '5px' }}>Top 3</span>
                </div>
                <div>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {
                            data?.topengaged.map((e, i) => {
                                return (
                                    <Grid item key={i} xs={4} sm={4} md={3.9} lg={3.9} xl={3.9} className="small-chips g-color" >
                                        <div className="small-circle div-center">
                                            {
                                                e.group_avatar ?
                                                    <img src={e.group_avatar} alt="message image" style={{ width: '28px', height: '28px', borderRadius: '170px' }} />
                                                    :
                                                    <span className="capital-letter">{e.group_name.split("")[0]}</span>
                                            }
                                        </div>
                                        <div className="small-text text-overflow">{e.group_name}</div>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </div>
            </div>
        </div >
    )
}
